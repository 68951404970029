import { withTranslation } from 'react-i18next';
import './Body.css';
import RandomRange from '../RandomRange';
import RandomList from '../RandomList';

const Body = ({ t }) => {

  return (
    <div>
      <div className="row margin-bottom-20">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 Body">
          <h2>{ t('body.aboutRandomTitle') }</h2>
          <p>
            { t('body.aboutRandomExplanation') }
            <pre>parseInt(Math.random() * (max - min + 1)) + min.</pre>
          </p>

          <h2>{ t('body.aboutSortTitle') }</h2>
          <p>
          { t('body.aboutSortExplanation') }
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 Body">
          <RandomRange />
        </div>

      </div>

      <div className="row margin-top-20">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 Body">
          <RandomList />
        </div>

      </div>

    </div>
  );
}

export default withTranslation('common')(Body);