import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './RandomRange.css';
import { calculateRandom } from '../../utils'


const RandomRange = ({ t }) => {

  const [result, setResult] = useState(null);
  const [history, appendHistory] = useState([]);
  const [min, setMin] = useState(1);
  const [max, setMax] = useState(15);

  const calculateAndAppendHistory = () => {
    const calculated = calculateRandom(min, max);
    appendHistory(actualState => [...actualState, calculated]);
    setResult(calculated);
  }

  return (
    <div className="row RandomRange">
      
      <div className="col-xs-12 col-lg-6 col-sm-6">
        <h2>{ t('randomRange.byRange') }</h2>

        <label htmlFor="rangeMin">{ t('randomRange.labelMin') }:</label>
        <input type="number" value={min}
          onChange={e => setMin(+e.target.value)}
          id="rangeMin" name="rangeMin" />

        <label htmlFor="rangeMax">{ t('randomRange.labelMax') }: </label>
        <input type="number" value={max}
          onChange={e => setMax(+e.target.value)}
          id="rangeMax" name="rangeMax" />

        <button onClick={() => calculateAndAppendHistory()}>{ t('randomRange.btnGenerate') }</button>

      </div>

      <div className="col-xs-12 col-lg-6 col-sm-6">
        <p>{ t('randomRange.explain') }</p>

        <div className="rangeResult">
          <b>{ t('randomRange.result') }</b>
          <h2>{result}</h2>
        </div>

        <div className="rangeHistory">
          <b>{ t('randomRange.history') }</b>
          <ul>
            {
              history.map((num, index) => {
                const order = index + 1;
                return <li key={`history-number-${index}`}>{order}°). {num}</li>
              })
            }
          </ul>
          <a href="#_" onClick={() => appendHistory([])} className="resetHistory">{ t('randomRange.clear') }</a>
        </div>

      </div>

    </div>
  );

}

export default withTranslation('common')(RandomRange);