import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './App.css';
import Head from './components/Head';
import Body from './components/Body';

function App() {
  return (
    <div className="App box">
      <I18nextProvider i18n={i18n}>
        <Head />
        <Body />
      </I18nextProvider>
    </div>
  );
}

export default App;
