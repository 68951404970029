import './Head.css';
import { withTranslation } from 'react-i18next';

const Head = (props) => {

	const { t } = props;

	return (
		<header className="Head">
			<div className="row middle-xs">
				<div className="col-xs-12 col-md-6 logo">
					<img src="logo512.png" alt="The Random Wizard" />
				</div>
				<div className="col-xs-12 col-md-6">
					<div className="box">
						<h1>The Random Wizard</h1>
						<span>{ t('head.subtitle') }</span>
					</div>
				</div>
			</div>
		</header>
	)
}

export default withTranslation('common')(Head);