
const calculateRandom = (min, max) => {
  let result = null;
  let i = 0;
  for(i = 0; i <= 100; i++){
    result = parseInt(Math.random() * (max - min + 1)) + min
  }
  
  return result;
}

export {
  calculateRandom
};