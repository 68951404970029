import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './RandomList.css';
import { calculateRandom } from '../../utils'

const RandomList = ({t}) => {

  const [history, appendHistory] = useState([]);
  const [sortedValue, setSortedValue] = useState(null);

  const sortAndPutHistory = () => {
    const textArea = document.querySelector('#nameList')
    if(textArea){
      const rows = textArea.value.split('\n').filter(item => item && item !== "");
      if(rows && rows.length) {
        const maxIndex = (rows.length - 1);
        const indexSorted = calculateRandom(0, maxIndex);
        const valueSorted = rows[indexSorted];
        setSortedValue(valueSorted);
        appendHistory(oldHistory => [...oldHistory, valueSorted])
      }
    }
  }

  return (
    <div className="row RandomList">

      <div className="col-xs-12 col-lg-6 col-sm-6">
        <h2>{ t('randomList.title') }</h2>

        <div>
          <b>{ t('randomList.result') }: </b> {sortedValue}
        </div>

        <div>
          <b>{ t('randomList.history') }: </b>
          {
            history.map((item, index) => {
              const order = index + 1;
              return <div key={`history-list-${index}`}>{order}°). {item}</div>
            })
          }
          <br/>
          <a href="#_" onClick={() => appendHistory([])} className="resetHistory">{ t('randomList.clear') }</a>
        </div>
      </div>

      <div className="col-xs-12 col-lg-6 col-sm-6">
        <p>{ t('randomList.explain') }</p>
        
        <label htmlFor="nameList">{ t('randomList.list') }:</label>
        <textarea name="nameList" id="nameList" rows="20"></textarea>

        <button onClick={ () => sortAndPutHistory() }>{ t('randomList.btnSort') }</button>
      </div>

    </div>
  )
}

export default withTranslation('common')(RandomList);