import pt from './default.json';
import en from './default.en.json';

import headPt from '../../components/Head/locales/default.json';
import headEn from '../../components/Head/locales/default.en.json';
import randomRangePt from '../../components/RandomRange/locales/default.json';
import randomRangeEn from '../../components/RandomRange/locales/default.en.json';
import randomListPt from '../../components/RandomList/locales/default.json';
import randomListEn from '../../components/RandomList/locales/default.en.json';
import bodyPt from '../../components/Body/locales/default.json';
import bodyEn from '../../components/Body/locales/default.en.json';

pt['pt-BR'].head = headPt['pt-BR'];
pt['pt-BR'].randomRange = randomRangePt['pt-BR'];
pt['pt-BR'].randomList = randomListPt['pt-BR'];
pt['pt-BR'].body = bodyPt['pt-BR'];

en['en'].head = headEn['en'];
en['en'].randomRange = randomRangeEn['en'];
en['en'].randomList = randomListEn['en'];
en['en'].body = bodyEn['en'];

export {
  pt,
  en,
};